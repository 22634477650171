import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from 'src/app/common/modules/material.module';
import { RouterModule } from '@angular/router';

import { ImageSliderModule } from 'src/app/common/modules/image-slider.module';

import { AboutUsComponent } from '../components/about-us/about-us.component';
import { BusinessgyaniComponent } from '../pages/businessgyani/businessgyani.component';
import { FooterComponent } from '../components/footer/footer.component';
import { GridLayoutComponent } from '../components/grid-layout/grid-layout.component';
import { HeaderComponent } from '../components/header/header.component';
import { OfficeDetailsComponent } from '../components/office-details/office-details.component';
import { ReadMoreComponent } from 'src/app/common/components/read-more/read-more.component';
import { ScrollToTopModule } from 'src/app/common/modules/scroll-to-top.module';
import { PrivacyAndPolicyComponent } from '../components/privacy-and-policy/privacy-and-policy.component';

@NgModule({
    declarations: [
        AboutUsComponent,
        BusinessgyaniComponent,
        FooterComponent,
        GridLayoutComponent,
        HeaderComponent,
        OfficeDetailsComponent,
        PrivacyAndPolicyComponent,
        ReadMoreComponent
    ],
    imports: [
        CommonModule,
        ImageSliderModule,
        MaterialModule,
        RouterModule, 
        ScrollToTopModule
    ],
    exports: [
        AboutUsComponent,
        BusinessgyaniComponent,
        FooterComponent,
        GridLayoutComponent,
        HeaderComponent,
        OfficeDetailsComponent,
        PrivacyAndPolicyComponent,
        ReadMoreComponent, 
        ScrollToTopModule
    ],
})

export class BusinessGyaniModule { }