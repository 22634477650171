<app-header></app-header>
<div class="office-details-container">
    <h2>Store Details</h2>
    <div class="logo">
        <img src="assets/logo/logo.png" width="100px" height="100px" alt="logo">
    </div>
    <div fxLayout fxLayoutAlign="center">
        <div fxLayout fxLayout.lt-md="column" fxLayoutGap.lt-md="10px">
            <div fxLayout="column" fxLayoutAlign="center space-between" fxLayoutGap.lt-md="10px" fxFlex.gt-md="60%"
                fxFlex [ngStyle.gt-md]="{'padding-left': '0px'}" [ngStyle.md]="{'padding-left': '200px'}"
                class="details-section">
                <div fxLayout fxLayoutGap="10px" [ngStyle.lt-md]="{'padding': '0px'}">
                    <mat-icon>call</mat-icon>
                    <div fxLayout="column">
                        <p>Contact Info</p>
                        9412488264
                    </div>
                </div>
                <div fxLayout fxLayoutGap="10px" [ngStyle.lt-md]="{'padding': '0px'}">
                    <mat-icon>email</mat-icon>
                    <div fxLayout="column">
                        <p>Email</p>
                        <a href="#">kakakohlu@gmail.com</a>
                    </div>
                </div>

            </div>
            <div fxLayout="column" fxLayoutAlign="center space-between" fxLayoutGap.lt-md="10px" fxFlex
                class="details-section">
                <div fxLayout fxLayoutGap="10px" [ngStyle.lt-md]="{'padding': '0px'}">
                    <mat-icon>home</mat-icon>
                    <div fxLayout="column">
                        <p>Address</p>
                        Mahaveer Ganj, Aligarh, UttarPradesh, 202001
                    </div>
                </div>
                <div fxLayout fxLayoutGap="10px" [ngStyle.lt-md]="{'padding': '0px'}">
                    <mat-icon>call</mat-icon>
                    <div fxLayout="column">
                        <p>Whatsapp Number</p>
                        +918791929495
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>