<div class="header-container">
    <!-- <span fxLayoutAlign="space-between center" fxLayout fxLayout.xs="column" fxLayoutAlign.xs="center end"> -->
    <!-- <span [ngStyle.gt-xs]="{'padding': '10px 0 10px 10px'}">Phone No. 9412488264 | 8791929495</span> -->
    <!-- <div><img src="assets/logo/header logo.png" class="logo" alt="logo"></div>  -->
    <div class="topnav" id="myTopnav">
        <a [routerLink]="['/kakaKohlu']">
            <mat-icon>home</mat-icon>
            <span>Home</span>
        </a>
        <a [routerLink]="['/OfficeDetails']" >
            <mat-icon>apartment</mat-icon>
            <span>Contact Us</span>
        </a>
        <a [routerLink]="['/aboutUs']">
            <mat-icon>people_alt</mat-icon>
            <span>About Us</span>
        </a>
        <a href="javascript:void(0);" class="icon" (click)="myFunction()">
            <mat-icon>subject</mat-icon>

        </a>
    </div>
</div>