<router-outlet></router-outlet>
<app-scroll-to-top></app-scroll-to-top>
<!-- <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"> -->

<!-- <div class="topnav" id="myTopnav">
  <a href="#home" class="active">Home</a>
  <a href="#news">News</a>
  <a href="#contact">Contact</a>
  <a href="#about">About</a>
  <a href="javascript:void(0);" class="icon" (click)="myFunction()">
    <i class="fa fa-bars"></i>
  </a>
</div> -->
