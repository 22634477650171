import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { APP_PATH } from './routes';

import { AboutUsComponent } from './businessgyani/components/about-us/about-us.component';
import { BusinessgyaniComponent } from './businessgyani/pages/businessgyani/businessgyani.component';
import { OfficeDetailsComponent } from './businessgyani/components/office-details/office-details.component';
import { PrivacyAndPolicyComponent } from './businessgyani/components/privacy-and-policy/privacy-and-policy.component';

const routes: Routes = [
  { path: APP_PATH.aboutUs, component: AboutUsComponent},
  { path: APP_PATH.kakaKohlu, component: BusinessgyaniComponent},
  { path: APP_PATH.OfficeDetails, component: OfficeDetailsComponent},
  { path: APP_PATH.privacyPolicy, component: PrivacyAndPolicyComponent},
  { path: '', pathMatch: 'full', redirectTo: APP_PATH.kakaKohlu},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }